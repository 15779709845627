$user-drawer-background: $colors-pure-white;

.root {
  /*position: fixed;*/
  /*position: fixed;*/
  /*top: 0;*/
  /*right: 0;*/
  /* TODO REMP: fixed position somehow doesn't work for scrolling, lets have relative position for now.
   * Problem with current solution is that card is centered (but card is scrollable).
   */

  width: 624px;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;

  background-color: $user-drawer-background;
}
