$bio-title: var(--palette-text-500);
$bio-text: var(--palette-text-100);

.title {
  text-transform: uppercase;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-3);
  margin: 0;
  line-height: 1.3;
  color: $bio-title;
}

.contents {
  color: $bio-text;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  margin: 0;
  line-height: 1.285;
}

.actions {
  margin-top: var(--spacing-3);
}
