.readOnly {
  cursor: not-allowed !important;
  color: var(--palette-text-500) !important;
  opacity: 1 !important;
}

.button {
  padding-left: 0;
}

.reacted {
  font-weight: var(--font-weight-primary-bold);
  color: var(--palette-primary-500);
}

.icon {
  /*REMP*/
  /*margin-right: var(--spacing-1);*/
  margin-left: var(--spacing-1);
}

.icon svg {
  display: block;
}

.totalReactions {
  margin-left: var(--spacing-1);
}
